import $ from "jquery";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
const intlUtilsScript = require(`${__dirname}/../../../../node_modules/intl-tel-input/build/js/utils.js`);

document.addEventListener("turbolinks:load", () => {
  const phoneInput = document.querySelector(".intlTelInput");
  if (phoneInput != null && phoneInput != undefined) {
    const iti = intlTelInput(phoneInput, {
      utilsScript: intlUtilsScript,
      hiddenInput: "phone_number",
      formatOnInit: true,
      separateDialCode: true
    });
  }
});
