import "./utils/phone_number";
import intlTelInput from "intl-tel-input";

const intlUtilsScript = require(`${__dirname}/../../../node_modules/intl-tel-input/build/js/utils.js`);

const handleIntlTelInput = () => {
  const phoneInput = document.querySelector(".intlTelInput");
  const callingCodeInput = document.getElementById("user_country_calling_code");

  if (phoneInput != null && phoneInput != undefined) {
    const iti = intlTelInput(phoneInput, {
      utilsScript: intlUtilsScript,
      hiddenInput: "phone_number",
      formatOnInit: true,
      separateDialCode: true
    });

    if (phoneInput.value) {
      iti.setNumber("+" + phoneInput.value);
    }

    phoneInput.addEventListener("countrychange", async (event) => {
      callingCodeInput.value = iti.getSelectedCountryData().dialCode;
    });
  }
};

const main = async () => {
  handleIntlTelInput();
};

document.addEventListener("turbolinks:load", () => {
  main();
});
